<template lang="pug">
TableV2(
  :isViewAdd="false"
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getCoursesETI"
  :isLoading="isLoading"
  isHiddenTableSearch
  isHiddenTableCount
  isHiddenPageCount
  componentInfo="BackOfficeCoursesList"
  showExpand
)
  CoursesAdd(:getCoursesETI="getCoursesETI" slot="AddBlock")
</template>

<script>
import CoursesAdd from './BackOfficeCoursesAdd/BackOfficeCoursesAdd.vue'
import { viewDetailedBlock, showDetailed, goBack } from '@/mixins/main'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BackOfficeCourses',
  components: {
    CoursesAdd
  },
  data () {
    return {
      headers: [
        { value: 'id',
          text: this.$t('id'),
          sortable: false
        },
        { value: 'name_ukr',
          text: this.$t('nameInstitution'),
          sortable: false
        },
        { value: 'name_abbr',
          text: this.$t('abbreviation'),
          sortable: false
        },
        { value: 'event',
          text: this.$t('actions'),
          class: 'mw-0'
        },
        { text: '', value: 'data-table-expand' }
      ],
      sortBy: 'nameInstitution',
      sortDesc: false,
      tableLoader: true,
      newDoc: false,
      showDetailed,
      viewDetailedBlock,
      goBack
    }
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.etiDirection,
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getCoursesETI'])
  }
}
</script>
